<template>
  <v-row>
    <v-col
      v-if="!prepopulate"
      cols="12"
    >
      <v-menu
        v-model="showContainersList"
        v-bind="attributes.menu"
        min-width="468"
        max-width="auto"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="query"
            ref="searchContainerField"
            label="Szukaj kontenera"
            :placeholder="getSearchShortkeysPlaceholder('kontenera')"
            outlined
            clearable
            autocomplete="off"
            v-bind="attrs"
            v-on="on"
            autofocus
            v-shortkey="searchShortkeys"
            @input="searchAddresses"
            @click:clear="clearAddressesList"
            @shortkey.native="focusSearchContainerField"
          >
            <template #prepend-inner>
              <Icon
                name="search"
                size="small"
                class="mr-2"
              />
            </template>
          </v-text-field>
        </template>

        <v-data-table
          ref="resultsTable"
          class="results-table"
          v-model="selectedContainer"
          :headers="resultsTableHeaders"
          :items="containersList"
          :items-per-page="-1"
          :loading="searchProcessing"
          hide-default-footer
          fixed-header
          single-select
          @click:row="selectContainer"
        />
      </v-menu>
    </v-col>

    <template>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-row class="pl-1 mb-3 mt-n1">
          <v-col
            cols="12"
            class="py-0"
          >
            <div class="input-label d-flex align-center text-14">
              <v-input
                :value="orderData.addressId"
                :rules="hideOrder ? [] : [rules.required]"
                class="validated-label"
                hide-details
              >
                Informacje o kliencie:
              </v-input>
              <span
                v-if="chosenClientData.blocked"
                class="red--text d-flex align-center ml-1"
              >
                <img
                  src="@/assets/icons/block-mark.svg"
                  class="mr-1"
                  alt="blocked"
                >
                Klient zablokowany
              </span>
            </div>
            <div class="chosen-client-details">
              {{ chosenClientData.name || '-' }}
            </div>

            <div class="chosen-client-details mt-1">
              {{ chosenClientData.invoiceAddress || '-' }}
            </div>
          </v-col>
          <v-col
            v-for="param in clientInfoParams"
            :key="param.text + 'client'"
            :cols="param.cols"
            class="pt-1 pb-0 pr-0 text-14"
          >
            <span class="input-label mr-1">{{ param.text }}: </span>
            <span class="chosen-client-details">{{ param.value || '-' }}</span>
          </v-col>

          <v-col
            cols="12"
            class="pt-6 pb-0"
          >
            <div class="input-label">
              Informacje o lokalizacji:
            </div>
          </v-col>

          <v-col
            v-for="param in addressInfoParams"
            :key="param.text + 'address'"
            :cols="param.cols"
            class="pt-1 pb-0 pr-0 text-14"
          >
            <span class="input-label mr-1">{{ param.text }}: </span>
            <span class="chosen-client-details">{{ param.value || '-' }}</span>
          </v-col>
          <template v-if="!orderData.anyContainer">
            <v-col
              cols="12"
              class="pt-6 pb-0"
            >
              <div class="input-label">
                Informacje o płatności:
              </div>
            </v-col>

            <v-col
              v-for="param in paymentInfoParams"
              :key="param.text + 'payment'"
              :cols="param.cols"
              class="pt-1 pb-0 pr-0 text-14"
            >
              <span class="input-label mr-1">{{ param.text }}: </span>
              <span class="chosen-client-details">{{ param.value || '-' }}</span>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </template>

    <template v-if="!prepopulate || fromClientApp">
      <v-col cols="4">
        <v-autocomplete
          outlined
          :items="quantityOptions"
          label="Liczba kontenerów"
          @input="emitUpdate('order.quantity', $event)"
          :value="orderData.quantity"
          :rules="[rules.required]"
          :disabled="!orderData.anyContainer || fromClientApp"
          placeholder="1"
          hide-details
          dense
        />
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          outlined
          :items="containerTypes"
          label="Typ kontenera"
          placeholder="-"
          @input="emitUpdate('order.pickupContainerTypeId', $event)"
          :value="orderData.pickupContainerTypeId"
          validate-on-blur
          hide-details
          :disabled="!orderData.anyContainer"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
          dense
        />
      </v-col>

      <v-col
        cols="4"
        class="d-flex align-center"
      >
        <v-checkbox
          :rules="[containersAvailability]"
          :input-value="orderData.anyContainer"
          :disabled="isProcessing || fromClientApp"
          hide-details
          class="my-0 pa-0"
          @change="changeAnyContainerStatus($event)"
        />
        <span
          class="input-label"
          :class="{ 'error--text': !containersAvailability }"
          v-text="'Dowolny kontener'"
        />
      </v-col>
    </template>

    <v-col
      cols="12"
      class="mt-2 pb-0"
      v-if="!orderData.anyContainer && !hideOrder"
    >
      <v-autocomplete
        outlined
        :items="ordersList"
        label="Zlecenie"
        @input="emitUpdate('order.orderId', $event)"
        :value="orderData.orderId"
        :rules="[rules.required]"
        item-text="parsedData"
        item-value="id"
        placeholder="Wybierz zlecenie"
        append-icon="mdi-chevron-down"
        no-data-text="Brak zleceń dla wybranego klienta"
        :disabled="prepopulate"
        dense
      >
        <template #prepend-inner>
          <Icon
            name="search"
            size="small"
            class="mr-2 mt-1"
          />
        </template>
      </v-autocomplete>
    </v-col>

    <v-col
      class="pt-0"
      v-if="distance"
    >
      <div class="input-label">
        Odległość od bazy w linii prostej: {{ distance }} km
      </div>
    </v-col>
  </v-row>
</template>

<script>
import api from '../../../api/v1'
import rules from '../../../utils/validators'
import {
  updateOrderFields,
  updatePaymentFields,
} from '../../../utils/fieldsUpdate'
import attributes from '../../../const/datePickerAttrributes'
import {
  searchShortkeys,
  getSearchShortkeysPlaceholder,
} from '../../../const/shortKeys'
import {
  formatValue
} from '../../../utils/typesEnum'
import debounce from 'lodash/debounce'
import { mapState, mapActions } from 'vuex'

const resultsTableHeaders = [
  { text: 'Klient', value: 'client.name' },
  { text: 'Adres', value: 'formattedAddress' },
  { text: 'Kontener', value: 'refNumberWithType' },
  { text: 'Nr BDO', value: 'client.bdoNumber' },
  { text: 'NIP', value: 'client.invoiceNip' },
  { text: 'Numer Telefonu', value: 'client.phoneNumber' },
]

export default {
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    paymentData: {
      type: Object,
      required: true,
    },
    specificClient: {
      type: Boolean,
      default: false,
    },
    prepopulate: {
      type: Boolean,
      default: false,
    },
    swapBaseOrder: {
      type: Object,
      deault: null,
    },
    distance: {
      type: Number,
      required: false,
    },
    hideOrder: {
      type: Boolean,
      default: false,
    },
    swap: {
      type: Boolean,
      default: false,
    },
    fromClientApp: {
      type: Boolean,
      default: false,
    },
    template: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      rules,
      resultsTableHeaders,
      searchShortkeys,
      attributes,
      query: '',
      clientId: null,
      addressesList: [],
      selectedContainer: [],
      selectedAddress: null,
      searchProcessing: false,
      showContainersList: false,
      anyContainersAvailable: false,
      anyContainersCount: null,
      isProcessing: false,
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      containerTypes: state => state.containerTypes.items,
      singleClient: state => state.client.entity,
      ordersList: state => state.orders.simplifiedList,
      populatedOrder: state => state.order.entity,
    }),
    chosenClientData() {
      return this.specificClient
        ? this.singleClient
        : this.selectedAddress?.client || {}
    },
    containersList() {
      return this.addressesList
        .map(address => {
          const { id: addressId, client, formattedAddress } = address
          return address.containers.map(container => ({
            ...container,
            addressId,
            client,
            formattedAddress,
          }))
        })
        .flat()
    },
    quantityOptions() {
      const count = this.swap ? 10 : this.anyContainersCount
      return [...Array(count).keys()].map(number => number + 1)
    },
    containersAvailability() {
      return !this.orderData.anyContainer || this.anyContainersAvailable
      // if anyContainer switch is off do not validate, else check if any container is available
    },
    clientInfoParams() {
      const {
        invoiceNip,
        phoneNumber,
        bdoNumber,
        bdoType,
        settlementType,
      } = this.chosenClientData
      return [
        { text: 'Telefon', value: phoneNumber, cols: 12 },
        { text: 'Typ dokumentu', value: settlementType, cols: 7 },
        { text: 'Numer NIP', value: invoiceNip, cols: 5 },
        { text: 'Logika BDO', value: bdoType, cols: 7 },
        { text: 'Nr BDO', value: bdoNumber, cols: 5 },
      ]
    },
    addressInfoParams() {
      const { formattedAddress, phoneNumber: addressPhoneNumber, notes } =
        this.selectedAddress?.address || this.selectedAddress || {}
      return [
        { text: 'Adres', value: formattedAddress, cols: 7 },
        { text: 'Telefon', value: addressPhoneNumber, cols: 5 },
        { text: 'Notatka', value: notes, cols: 12 },
      ]
    },
    paymentInfoParams() {
      const { settlementType, paymentType, paymentDueDays, vatPercentage, debrisGrossValue, debrisNetValue } =
        this.paymentData || {}

      return [
        { text: 'Typ rozliczenia', value: settlementType, cols: 4 },
        { text: 'Typ płatności', value: paymentType, cols: 4 },
        { text: 'Termin płatności', value: formatValue(paymentDueDays, 'days'), cols: 4 },
        { text: 'Stawka vat', value: formatValue(vatPercentage, 'percentage'), cols: 4 },
        { text: 'Cena netto', value: formatValue(debrisNetValue, 'price'), cols: 4 },
        { text: 'Cena brutto', value: formatValue(debrisGrossValue, 'price'), cols: 4 },
      ]
    },
  },
  mounted() {
    if (this.specificClient) {
      this.query = this.singleClient.name
      this.searchAddresses()
    }
    if (this.prepopulate) {
      const order = this.swapBaseOrder || this.populatedOrder
      // in case of editing, order field should show base order in any other scenario current order
      this.emitUpdate('order.orderId', order.id)
      this.selectedAddress = this.template || order
      this.$store.commit('orders/SET_SIMPLIFIED_ITEMS', [order])
    }
    if (this.fromClientApp) {
      this.checkAddressOrdersAvailability()
    }
  },
  beforeDestroy() {
    this.clearSimplifiedOrdersList()
  },
  methods: {
    ...mapActions({
      getSimplifiedOrders: 'orders/getSimplifiedItems',
      clearSimplifiedOrdersList: 'orders/clearSimplifiedList',
      showSnackbar: 'snackbar/showSnackbar',
    }),
    getSearchShortkeysPlaceholder,
    selectContainer(container, row) {
      row.select(true)
      this.showContainersList = false
      const selectedAddress = this.addressesList.find(
        address => address.id === container.addressId
      )
      this.selectedAddress = selectedAddress
      const { paymentType, paymentDueDays } = selectedAddress
      if (paymentType) this.emitUpdate('payment.paymentType', paymentType)
      if (paymentDueDays) { this.emitUpdate('payment.paymentDueDays', paymentDueDays) }

      this.emitUpdate('order.addressId', container.addressId)
      this.checkAddressOrdersAvailability()

      this.clearContainerData()
      const params = {
        departmentId: this.department.id,
        swapOrPickup: true,
        filters: {
          clientIds: container?.client?.id ? [container.client.id] : undefined,
        },
      }
      const { anyContainer } = this.orderData
      if (anyContainer) this.populateFieldsWithLastDoneOrder()
      this.isProcessing = true
      this.getSimplifiedOrders({ params }).then(() => {
        if (!anyContainer) {
          const { id } =
            this.ordersList.find(
              order => order.refNumber === container.orderRefNumber
            ) || {}
          this.emitUpdate('order.orderId', id)
        }
      })
        .finally(() => {
          this.isProcessing = false
        })
    },
    changeAnyContainerStatus(isAnyContainer) {
      this.emitUpdate('order.anyContainer', isAnyContainer)
      if (isAnyContainer) {
        this.emitUpdate('order.orderId', null)
        if (this.selectedAddress) this.populateFieldsWithLastDoneOrder()
        this.emitUpdate('order.userNotes', null)
        this.checkAddressOrdersAvailability()
      } else {
        this.clearContainerData()
        this.anyContainersAvailable = false
      }
    },
    checkAddressOrdersAvailability() {
      const { anyContainer, addressId } = this.orderData
      if (anyContainer && addressId) {
        this.isProcessing = true
        api
          .checkAddressOrders(addressId)
          .then(resp => {
            const {
              message,
              messageType,
              coursesCount,
              ordersCount,
            } = resp.data
            this.anyContainersCount = +ordersCount - +coursesCount
            this.anyContainersAvailable = this.swap || messageType !== 'error'
            this.showSnackbar({
              message: [message],
              type: messageType,
            })
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    },
    clearContainerData() {
      this.emitUpdate('order.quantity', 1)
      this.emitUpdate('order.pickupContainerTypeId', null)
    },
    clearAddressesList() {
      this.addressesList = []
    },
    searchAddresses: debounce(function() {
      if (this.query) {
        this.searchProcessing = true
        this.showContainersList = true

        const clientId = this.specificClient ? this.singleClient.id : undefined
        api
          .searchAddresses({
            departmentId: this.department.id,
            clientId,
            filters: {
              query: this.query,
            },
          })
          .then(resp => {
            this.addressesList = resp.data
          })
          .finally(() => {
            this.searchProcessing = false
          })
      } else {
        this.clearAddressesList()
      }
    }, 700),
    emitUpdate(key, value) {
      this.$emit('update', { key, value })
    },
    populateFieldsWithLastDoneOrder() {
      const { id, client } = this.selectedAddress
      this.isProcessing = true
      api
        .getClientLastDoneOrder(client.id, {
          addressId: id,
          orderType: 'standard',
        })
        .then(resp => {
          if (resp.data[0]) {
            updateOrderFields.call(this, resp.data[0])
            this.emitUpdate('order.userNotes', null)
            if (this.swap) updatePaymentFields.call(this, resp.data[0])
          }
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    focusSearchContainerField() {
      this.$refs.searchContainerField.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-list-item__content > .v-list-item__title {
  white-space: wrap !important;
  text-overflow: clip !important;
  white-space: break-spaces !important;
}
</style>
